@tailwind base;
@tailwind components;
@tailwind utilities;

@import './config';

html, body {
	scroll-behavior: smooth;
}

body {
    font-family: $fontPrimary;
    color: $textWhite !important;
    background-color: $backgroundDefault !important;
}

.black-shadow {
    box-shadow: 0px 3.43925px 30px #000000;
}

.white-shadow {
    box-shadow: 0px 3.43925px 24px -6px rgba(255, 239, 173, 0.8);
}

.primary-main-shadow {
    box-shadow: 0px 3.43925px 24px -6px $primaryMain;
}

.primary-main-shadow-with-hover {
    box-shadow: 0px 3.43925px 16px -6px $primaryMain;
	&:hover {
		box-shadow: 0px 3.43925px 44px -3px $primaryMain;
	}
}

.floating { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
 
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
}

.rec {
	&.rec-slider-container {
		margin: 0px;
	}
	&.rec-arrow {
		background: none;
		color: $primaryMain;
		box-shadow: none;
		&.rec-arrow-left {
            &:focus {
				background: none;
				box-shadow: none;
			}
			&:focus-visible {
				background: none;
			}
			&:disabled {
				cursor: default;
				opacity: 0.4;
			}
			&:hover {
				box-shadow: none;
				background: none;
			}
		}
		&.rec-arrow-right {
            &:focus {
				background: none;
				box-shadow: none;
			}
			&:focus-visible {
				background: none;
			}
			&:disabled {
				cursor: default;
				opacity: 0.4;
			}
			&:hover {
				box-shadow: none;
				background: none;
			}
		}
	}
}

.list-lower-alpha{
list-style-type: lower-alpha;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 4px solid #ccc;
  border-top-color: #888;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}